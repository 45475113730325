/**
* SEO component that queries for data with
* Gatsby's useStaticQuery React hook
*
* See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type AppProps = {
	description?: string
	image?: string
	lang?: string
	meta?: []
	title: string
	url: string
}

const SEO: React.FC<AppProps> = ({
	description,
	image,
	lang,
	meta,
	title,
	url,
}) => {
	const { site } = useStaticQuery(
		graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
					image
				}
			}
		}
		`
	)

	const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
			htmlAttributes={ {
				lang,
			} }
			meta={ [
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: `${title} | A Memoir Project`,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: url,
				},
				{
					property: `og:image`,
					content: image !== '' ? image : site.siteMetadata.image,
				},
				{
					property: `fb:app_id`,
					content: '677919939703768',
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: `${title} | A Memoir Project`,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:url`,
					content: url,
				},
				{
					name: `twitter:image`,
					content: image !== '' ? image : site.siteMetadata.image,
				},
			].concat(meta) }
			title={ title }
			titleTemplate={ `%s | ${site.siteMetadata.title}` }
		/>
	)
}

SEO.defaultProps = {
	description: ``,
	image: '',
	lang: `en`,
	meta: [],
}

export default SEO
